import React, { useState } from 'react';
import AuthService from './AuthService ';
import './ScrapeForwardToggle.css';

const ScrapeForwardToggle = ({ setScrapeForwardActive }) => {
    const [toggleState, setToggleState] = useState(false);

    const handleToggle = async () => {
        const newToggleState = !toggleState;
        setToggleState(newToggleState);
        setScrapeForwardActive(newToggleState); // Update the parent state

        const updateToggleState = async (token) => {
            try {
                // console.log('Updating scrape_forward_mode to:', newToggleState);
                const response = await fetch(
                    `https://noisefilter.app/api/scrape_forward/update_mode?scrape_forward_mode=${newToggleState}`,
                    {
                        method: 'PUT',
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                    }
                );

                if (response.ok) {
                    // console.log('Scrape forward mode updated successfully');
                } else if (response.status === 401) {
                    console.warn('Unauthorized, attempting token refresh...');
                    const newToken = await AuthService.refreshToken();
                    if (newToken) {
                        // console.log('Token refreshed successfully');
                        localStorage.setItem('access_token', newToken);
                        await updateToggleState(newToken);
                    } else {
                        console.error('Token refresh failed, reverting state');
                        setToggleState(!newToggleState); // Revert toggle state
                        setScrapeForwardActive(!newToggleState); // Revert parent state
                    }
                } else {
                    console.error('Failed to update scrape forward mode, reverting state');
                    setToggleState(!newToggleState); // Revert toggle state
                    setScrapeForwardActive(!newToggleState); // Revert parent state
                }
            } catch (error) {
                console.error('Error updating scrape forward mode:', error);
                setToggleState(!newToggleState); // Revert toggle state
                setScrapeForwardActive(!newToggleState); // Revert parent state
            }
        };

        const accessToken = localStorage.getItem('access_token');
        if (accessToken) {
            // console.log('Found access token:', accessToken);
            updateToggleState(accessToken);
        } else {
            console.error('No access token found, reverting state');
            setToggleState(!newToggleState);
            setScrapeForwardActive(!newToggleState); // Revert parent state
        }
    };

    return (
        <div className="toggle-container">
            <label className="toggle-label">
                <input
                    type="checkbox"
                    className="toggle-input"
                    checked={toggleState}
                    onChange={handleToggle}
                />
                <span className="toggle-slider"></span>
            </label>
            <span className="toggle-text">Scrape & Forward Mode</span>
        </div>
    );
};

export default ScrapeForwardToggle;
