import React, { useState, useEffect, useRef } from 'react';
import { Chat } from "../webSocket/one/Chat";
import { ChatDelete } from "../webSocket/two/ChatDelete";
import { Filter } from "../webSocket/one/Filter";
import { FilterDelete } from "../webSocket/two/FilterDelete";
import { HeaderChat } from "../webSocket/one/HeaderChat";
import { WebSocketDemo } from "../webSocket/WebSocketDemo";
import { SidebarRight } from "../webSocket/SidebarRight";
import '../webSocket/main.scss';
import {debounce} from "@mui/material";
import useAuthCheck from "../webSocket/component/useAuthCheck";

const Home = () => {
    useAuthCheck();



    const [messages, setMessages] = useState([]);
    // console.log(messages)
    const [filters, setFilters] = useState({
        username: null,
        chat_title: null,
        content: null,
        startswith: null,
    });

    const [filtersTwo, setFiltersTwo] = useState({
        username: [],
        chat_title: [],
        content: [],
        startswith: [],
    });
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [activeTab, setActiveTab] = useState('all');
    const chatRef = useRef(null);
    const chatDeleteRef = useRef(null);
    const [unreadCount, setUnreadCount] = useState(0);
    const [scrollState, setScrollState] = useState('last'); // 'last' or 'first'
    const [firstWebSocketMessageId, setFirstWebSocketMessageId] = useState(null); // First WebSocket message ID
    const [lastWebSocketMessageId, setLastWebSocketMessageId] = useState(null); // Last WebSocket message ID

    const handleApplyFilters = (appliedFilters, startDateTimestamp, endDateTimestamp) => {

        // console.log(startDateTimestamp,endDateTimestamp)
        // console.log(appliedFilters.chats.id , appliedFilters)

        const finalFilters = {
            ...appliedFilters,
            startDateTimestamp,
            endDateTimestamp,
            chats: appliedFilters.chats || [],
        };

        setFilters(finalFilters);
        setIsFilterVisible(false);

        if (activeTab === 'deleted' && chatDeleteRef.current && typeof chatDeleteRef.current.fetchDeletedMessages === 'function') {
            chatDeleteRef.current.fetchDeletedMessages(startDateTimestamp, endDateTimestamp);
        }

    };

    const debouncedApplyFilters = debounce((finalFilters) => {
        if (chatRef.current) {
            chatRef.current.fetchMoreMessages(finalFilters); // Виклик з новими фільтрами
        }
    }, 300);  // Затримка для дебаунсу



    const handleApplyFiltersNew = (appliedFilters) => {
        // console.log("LIKE", appliedFilters)

        const { username, chat_title, content, startswith } = appliedFilters;

        const finalFilters = {
            username: username && username.length > 0 ? username : null,
            chat_title: chat_title && chat_title.length > 0 ? chat_title : null,
            content: content && content.length > 0 ? content : null,
            startswith: startswith && startswith.length > 0 ? startswith : null,
        };
        // console.log("LIKE", appliedFilters)

        if (JSON.stringify(filters) === JSON.stringify(finalFilters)) {
            // console.log("Filters are the same, skipping the request.");
            return;  // Skip if no changes
        }

        // console.log("Applying filters:", finalFilters);
        setFilters(finalFilters);

        debouncedApplyFilters(finalFilters);
        // console.log("LIKE", appliedFilters)
        setFilters(appliedFilters)
        setMessages([]);

    };


    const handleFetchMoreMessages = (resetFilters) => {
        chatRef.current.fetchMoreMessages(resetFilters);


    };



    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };



    const handleScrollToMessage = () => {
        if (scrollState === 'last' && chatRef.current && firstWebSocketMessageId) {
            // Check if an "Unread messages" marker already exists
            if (!document.querySelector('.unread-messages-marker')) {
                // Create a new "Unread messages" element
                const unreadMessageElement = document.createElement('div');
                unreadMessageElement.textContent = 'Unread messages';
                unreadMessageElement.style.textAlign = 'center';
                unreadMessageElement.style.fontWeight = 'bold';
                unreadMessageElement.style.color = '#ffffff'; // White text for dark theme
                unreadMessageElement.className = 'unread-messages-marker';

                // Find the first unread message element
                const firstMessageElement = document.getElementById(`message-${firstWebSocketMessageId}`);
                if (firstMessageElement && firstMessageElement.parentElement) {
                    // Insert the "Unread messages" element above the first unread message
                    firstMessageElement.parentElement.insertBefore(unreadMessageElement, firstMessageElement);

                    // Set a timeout to remove the "Unread messages" element after 10 seconds
                    setTimeout(() => {
                        unreadMessageElement.remove();
                    }, 10000); // 10,000 milliseconds = 10 seconds
                }
            }

            // Scroll to the first WebSocket message
            chatRef.current.scrollToMessage(firstWebSocketMessageId);

            // Switch to 'first' after scrolling to the first message
            setScrollState('first');
        }
        else if (scrollState === 'first' && chatRef.current && lastWebSocketMessageId) {
            // Scroll to the last WebSocket message
            chatRef.current.scrollToLastMessage();
            // console.log("Scrolled to the last message:", lastWebSocketMessageId);

            // Switch to 'last' after scrolling to the last message
            setScrollState('last');
            setUnreadCount(0); // Reset unread count after scrolling to the last message
        }
        else {
            // console.log('No valid WebSocket message IDs to scroll to.');
        }
    };
    const handleScrollToBottomDelete = () => {
        if (chatDeleteRef.current) {
            // Scroll to the bottom of the messages in ChatDelete
            chatDeleteRef.current.scrollToLastMessageDelete();
            // console.log("Scrolled to the bottom in ChatDelete");

            // Optionally, you can reset the unread count if necessary
            setUnreadCount(0);
        } else {
            // console.log('ChatDelete ref is not available to scroll.');
        }
    };



    const renderChatComponent = () => {
        if (activeTab === 'all') {
            return <Chat
                messages={messages}
                setMessages={setMessages}
                filters={filters}
                setUnreadCount={setUnreadCount}
                firstWebSocketMessageId={firstWebSocketMessageId}
                lastWebSocketMessageId={lastWebSocketMessageId}
                setFirstWebSocketMessageId={setFirstWebSocketMessageId}
                ref={chatRef}
            />;
        } else if (activeTab === 'deleted') {
            // Ensure ref is not null before calling scrollToLastMessageDelete
            if (chatDeleteRef.current) {
                chatDeleteRef.current.scrollToLastMessageDelete();
            }

            return <ChatDelete
                ref={chatDeleteRef}
                filters={filters}
                setMessages={setMessages}
                setUnreadCount={setUnreadCount}
            />;
        }
    };



    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="container custom-background">
            {!isMobile && (activeTab === 'all' ?
                <Filter onApplyFilters={handleApplyFiltersNew} fetchMoreMessages={handleFetchMoreMessages}
                        setMessages={setMessages}
                        filters={filters} setFilters={setFilters}
                        activeTab={activeTab}
                        setUnreadCount={setUnreadCount}
                        setFirstWebSocketMessageId={setFirstWebSocketMessageId}
                        setLastWebSocketMessageId={setLastWebSocketMessageId}/> :
                <FilterDelete onApplyFilters={handleApplyFilters} />)}

            <div className="container-chat">
                {isMobile && isFilterVisible && (
                    <>
                        <HeaderChat
                            setIsFilterVisible={setIsFilterVisible}
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                        />
                        <div className="filter-container">
                            {activeTab === 'all' ?
                                <Filter onApplyFilters={handleApplyFiltersNew} fetchMoreMessages={handleFetchMoreMessages}
                                        setMessages={setMessages}
                                        filters={filters}
                                        activeTab={activeTab}
                                        setUnreadCount={setUnreadCount}
                                        setFirstWebSocketMessageId={setFirstWebSocketMessageId}
                                        setLastWebSocketMessageId={setLastWebSocketMessageId}/> :
                                <FilterDelete onApplyFilters={handleApplyFilters} />}
                        </div>
                    </>
                )}

                {isMobile && !isFilterVisible && (
                    <>
                        <div className="wrapper-chat">
                            <WebSocketDemo
                                setMessages={setMessages}
                                filters={filters}
                                activeTab={activeTab}
                                setUnreadCount={setUnreadCount}
                                setFirstWebSocketMessageId={setFirstWebSocketMessageId}
                                setLastWebSocketMessageId={setLastWebSocketMessageId}
                            />
                            {renderChatComponent()}
                        </div>

                        <HeaderChat
                            setIsFilterVisible={setIsFilterVisible}
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                        />
                    </>
                )}


                {!isMobile && (
                    <>
                        <HeaderChat
                            setIsFilterVisible={setIsFilterVisible}
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                        />
                        <div className="wrapper-chat">
                            <WebSocketDemo
                                setMessages={setMessages}
                                filters={filters}
                                activeTab={activeTab}
                                setUnreadCount={setUnreadCount}
                                setFirstWebSocketMessageId={setFirstWebSocketMessageId}
                                setLastWebSocketMessageId={setLastWebSocketMessageId}
                            />
                            {renderChatComponent()}
                            <SidebarRight />
                        </div>
                    </>
                )}

                {!isMobile || !isFilterVisible ? (
                    <div className="scroll-button" onClick={activeTab === 'deleted' ? handleScrollToBottomDelete : handleScrollToMessage}>
                        {unreadCount > 0 && activeTab !== 'deleted' && ( // Запобігання відображенню лічильника в ChatDelete
                            <div className="unread-count">
                                {unreadCount > 99 ? '99+' : unreadCount} {/* Відображення '99+' для кількості, більшої за 99 */}
                            </div>
                        )}
                        <div className="arrow-icon"></div>
                    </div>

                ) : null}


            </div>
        </div>
    );
};

export default Home;
