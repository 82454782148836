import React, { useState, useEffect } from 'react';
import SidebarLayout from '../page/SidebarLayout';
import '../../styles/account.scss';
import AuthService from "../../webSocket/component/AuthService ";
import useAuthCheck from "../../webSocket/component/useAuthCheck";

const AccountManagement = () => {
    useAuthCheck();

    const [selectedAll, setSelectedAll] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const access_token = localStorage.getItem('access_token');


    useEffect(() => {



        const fetchAccounts = async (token) => {
            try {
                const response = await fetch('https://noisefilter.app/api/accounts_all/user/me', {
                    headers: {
                        'accept': 'application/json',
                        'Authorization': `bearer ${token}`
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    // console.log(data)
                    setAccounts(data);
                } else if (response.status === 401) {
                    console.error("Unauthorized, refreshing token...");
                    const newToken = await AuthService.refreshToken();
                    if (newToken) {
                        localStorage.removeItem('access_token');
                        localStorage.setItem('access_token', newToken);
                        await fetchAccounts(newToken);
                    } else {
                        console.error("Token refresh failed, redirecting to login...");
                    }
                } else {
                    console.error('Failed to fetch accounts');
                }
            } catch (error) {
                console.error('Error fetching accounts:', error);
            }
        };

        if (access_token) {
            fetchAccounts(access_token);
        } else {
            console.error('Access token not found in localStorage');
        }
    }, [access_token]);

    const handleSelectAll = () => {
        const newSelectedState = !selectedAll;
        setSelectedAll(newSelectedState);
        setAccounts(accounts.map(account => ({ ...account, selected: newSelectedState })));
    };

    const handleSelectAccount = (id) => {
        setAccounts(prevAccounts =>
            prevAccounts.map(account =>
                account.id === id ? { ...account, selected: !account.selected } : account
            )
        );
    };

    const toggleAccountStatus = async (id, phone, currentStatus) => {
        const newStatus = !currentStatus;

        const updateAccountStatus = async (token) => {
            try {
                const response = await fetch(`https://noisefilter.app/api/account_set_active/${phone}/${newStatus}`, {
                    method: 'POST',
                    headers: {
                        'accept': 'application/json',
                        'Authorization': `bearer ${token}`
                    },
                    body: ''
                });

                if (response.ok) {
                    setAccounts(prevAccounts =>
                        prevAccounts.map(account =>
                            account.id === id ? { ...account, active: newStatus } : account
                        )
                    );
                } else if (response.status === 401) {
                    console.error("Unauthorized, refreshing token...");
                    const newToken = await AuthService.refreshToken();
                    if (newToken) {
                        localStorage.removeItem('access_token');
                        localStorage.setItem('access_token', newToken);
                        await updateAccountStatus(newToken);
                    } else {
                        console.error("Token refresh failed, redirecting to login...");
                    }
                } else {
                    console.error('Failed to update account status');
                }
            } catch (error) {
                console.error('Error updating account status:', error);
            }
        };

        if (access_token) {
            updateAccountStatus(access_token);
        } else {
            console.error('Access token not found in localStorage');
        }
    };

    return (
        <SidebarLayout>
            <div className="account-management-container">
                <div className="account-content">
                    <h2 className="account-title">Accounts</h2>
                    <div className="table-container">
                        <table className="account-table">
                            <thead>
                            <tr>
                                <th>
                                    <input
                                        type="checkbox"
                                        checked={selectedAll}
                                        onChange={handleSelectAll}
                                    />
                                </th>
                                <th>Phone</th>
                                <th>Username</th>
                                <th>Active</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {accounts.map(account => (
                                <tr key={account.id}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={account.selected || false}
                                            onChange={() => handleSelectAccount(account.id)}
                                        />
                                    </td>
                                    <td>{account.id}</td>
                                    <td>{account.username}</td>
                                    <td>
                                        <span className={`status-${account.active ? 'active' : 'inactive'}`}>
                                            {account.active ? 'Yes' : 'No'}
                                            <span className={`status-icon-${account.active ? 'active' : 'inactive'}`}></span>
                                        </span>
                                    </td>
                                    <td>
                                        <button
                                            className={`action-button ${account.active ? 'stop-button' : 'start-button'}`}
                                            onClick={() => toggleAccountStatus(account.id, account.id, account.active)}
                                        >
                                            {account.active ? 'Stop' : 'Start'}
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </SidebarLayout>
    );
};

export default AccountManagement;
