import React, { useState, useEffect } from 'react';
import SidebarLayout from './page/SidebarLayout';
import '../styles/issues.scss';
import AuthService from "../webSocket/component/AuthService ";
import useAuthCheck from "../webSocket/component/useAuthCheck";

const Issues = () => {
    useAuthCheck();
    const [toggleState, setToggleState] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const fetchToggleState = async (token) => {
            try {
                const response = await fetch(`https://noisefilter.app/api/users/me`, {
                    method: 'GET',
                    headers: {
                        'accept': 'application/json',
                        'Authorization': `bearer ${token}`
                    },
                });

                if (response.ok) {
                    const userData = await response.json();
                    setToggleState(userData.tg_notifications); // Set toggle state based on tg_notifications
                } else if (response.status === 401) {
                    console.error("Unauthorized, refreshing token...");
                    const newToken = await AuthService.refreshToken();
                    if (newToken) {
                        localStorage.removeItem('access_token');
                        localStorage.setItem('access_token', newToken);
                        await fetchToggleState(newToken);
                    } else {
                        console.error("Token refresh failed, redirecting to login...");
                    }
                } else {
                    console.error('Failed to fetch Telegram notifications status');
                }
            } catch (error) {
                console.error('Error:', error);
            } finally {
                setLoaded(true); // State has been loaded
            }
        };

        const access_token = localStorage.getItem('access_token');
        if (access_token) {
            fetchToggleState(access_token);
        } else {
            console.error('User ID not found in localStorage');
        }
    }, []);

    const handleToggle = async () => {
        const newToggleState = !toggleState;
        setToggleState(newToggleState); // Immediately update the UI

        const updateToggleState = async (token) => {
            const status = newToggleState ? 'true' : 'false';

            try {
                const response = await fetch(`https://noisefilter.app/api/users/set_telegram_notifications/me/${status}`, {
                    method: 'POST',
                    headers: {
                        'accept': 'application/json',
                        'Authorization': `bearer ${token}`
                    },
                });

                if (response.ok) {
                    // console.log('Telegram notifications status updated successfully');
                } else if (response.status === 401) {
                    console.error("Unauthorized, refreshing token...");
                    const newToken = await AuthService.refreshToken();
                    if (newToken) {
                        localStorage.removeItem('access_token');
                        localStorage.setItem('access_token', newToken);
                        await updateToggleState(newToken);
                    } else {
                        console.error("Token refresh failed, redirecting to login...");
                        setToggleState(!newToggleState); // Revert toggle state if the fetch fails
                    }
                } else {
                    console.error('Failed to update Telegram notifications status');
                    setToggleState(!newToggleState); // Revert toggle state if the fetch fails
                }
            } catch (error) {
                console.error('Error:', error);
                setToggleState(!newToggleState); // Revert toggle state if there's an error
            }
        };

        const access_token = localStorage.getItem('access_token');
        if (access_token) {
            updateToggleState(access_token);
        } else {
            console.error('User ID not found in localStorage');
        }
    };

    const handleButtonClick = async () => {
        const fetchTelegramLink = async (token) => {
            try {
                const response = await fetch(`https://noisefilter.app/api/users/get_telegram_link/me`, {
                    method: 'GET',
                    headers: {
                        'accept': 'application/json',
                        'Authorization': `bearer ${token}`
                    },
                });

                if (response.ok) {
                    const telegramLink = await response.json();
                    window.open(`https://${telegramLink}`, '_blank');
                } else if (response.status === 401) {
                    console.error("Unauthorized, refreshing token...");
                    const newToken = await AuthService.refreshToken();
                    if (newToken) {
                        localStorage.removeItem('access_token');
                        localStorage.setItem('access_token', newToken);
                        await fetchTelegramLink(newToken);
                    } else {
                        console.error("Token refresh failed, redirecting to login...");
                    }
                } else {
                    console.error('Failed to fetch the Telegram link');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        const access_token = localStorage.getItem('access_token');
        if (access_token) {
            fetchTelegramLink(access_token);
        } else {
            console.error('User ID not found in localStorage');
        }
    };

    return (
        <SidebarLayout>
            <div className="issues-container">
                <div className="issues-content">
                    <h2 className="issues-title">Manage Your Notifications</h2>
                    <p className="issues-description">
                        Enable or disable Telegram notifications directly from your dashboard. Get instant updates about your projects.
                    </p>
                    <button className="issues-button" onClick={handleButtonClick}>
                        Get Telegram Link
                    </button>
                    <div className="issues-toggle-container">
                        <p className="issues-toggle-label">Telegram Notifications:</p>
                        <div className="toggle-switch">
                            <label className={`switch ${loaded ? '' : 'no-animation'}`}>
                                <input type="checkbox" checked={toggleState} onChange={handleToggle} />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="issues-illustration">
                    {/* Add your illustration or image here */}
                    {/*<img src="path-to-your-image.png" alt="Notifications Management" />*/}
                </div>
            </div>
        </SidebarLayout>
    );
};

export default Issues;
