import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import InputField from '../input/InputField';
import "./telegram.css";
import useFlexCenter from "../input/useFlexCenter";
import HeaderImagePhoto from "../icon/img_29.png";
import HeaderImage from "../icon/img_30.png";
import HeaderImageWhite from "../icon/noiseWhite.png";
import AuthService from "../webSocket/component/AuthService ";
import useAuthCheck from "../webSocket/component/useAuthCheck";

function ConfirmTelegram() {
    useAuthCheck();

    const location = useLocation();
    const navigate = useNavigate();
    const [phone, setPhone] = useState(location.state?.phone.replace('+', '') || "");
    const [code, setCode] = useState("");
    const [username, setUsername] = useState(location.state?.username || localStorage.getItem('username') || ""); // Check for username in state or localStorage
    const [isLoading, setIsLoading] = useState(false);
    const access_token = localStorage.getItem('access_token');

    // Fallback in case username wasn't passed through state
    useEffect(() => {
        if (!username) {
            const storedUsername = localStorage.getItem('username');
            if (storedUsername) {
                setUsername(storedUsername);
            }
        }
    }, [username]);

    const handleGoBack = () => {
        navigate("/connect-telegram");
    };

    const handleSendCode = async () => {
        setIsLoading(true); // Start loading

        const sendCodeRequest = async (token) => {
            try {
                const url = `https://noisefilter.app/api/bot/pass_code/${phone}/${code}/${username}`;
                // console.log("Request URL:", url);

                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `bearer ${token}`
                    }
                });

                const data = await response.json();
                // console.log("Response data:", data);

                if (response.ok) {
                    if (data === "Password") {
                        navigate("/password", { state: { phone, username } }); // Navigate to password page
                    } else {
                        navigate("/congratulations");
                    }
                } else if (response.status === 401) {
                    console.error("Unauthorized, refreshing token...");
                    const newToken = await AuthService.refreshToken();
                    if (newToken) {
                        localStorage.removeItem('access_token');
                        localStorage.setItem('access_token', newToken);
                        await sendCodeRequest(newToken);
                    } else {
                        console.error("Token refresh failed, redirecting to login...");
                    }
                } else {
                    throw new Error(data.detail);
                }
            } catch (error) {
                console.error('Error:', error);
            } finally {
                setIsLoading(false); // End loading
            }
        };

        await sendCodeRequest(access_token);
    };

    useFlexCenter('flex-center');

    return (
        <>
            {!isLoading && (
                <div className="header-container-white-telegram">
                    <img src={HeaderImagePhoto} alt="Header" className="header-image-white"/>
                    <img src={HeaderImage} alt="Header" className="header-image"/>
                </div>
            )}

            <center>
                {isLoading && (
                    <img src={HeaderImageWhite} alt="Loading..." className="loading-indicator-telegram"/>
                )}
            </center>

            <div className={`confirm-telegram-card ${isLoading ? 'loading' : ''}`}>
                <h2>Connect your Telegram</h2>
                <p>We've sent a confirmation code to {phone}, please enter the code below</p>
                <InputField
                    type="tel"
                    placeholder="Phone Number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
                <InputField
                    type="text"
                    placeholder="Enter received code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                />
                <div className="button-group">
                    <button className="go-back-btn" onClick={handleGoBack} disabled={isLoading}>Go Back</button>
                    <button className="confirm-btn" onClick={handleSendCode} disabled={isLoading}>
                        {isLoading ? "Processing..." : "Confirm"}
                    </button>
                </div>
            </div>
        </>
    );
}

export default ConfirmTelegram;
