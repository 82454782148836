import React, { useEffect, useRef, useState, forwardRef, useCallback, useImperativeHandle } from 'react';
import DOMPurify from "dompurify";
import '../chat.scss';
import AuthService from "../component/AuthService ";
import useAuthCheck from "../component/useAuthCheck";

export const Chat = forwardRef(({ messages, setFirstWebSocketMessageId, setMessages, filters, setUnreadCount, firstWebSocketMessageId, lastWebSocketMessageId }, ref) => {
    useAuthCheck();


    const containerRef = useRef(null);
    const loadMoreRef = useRef(null);
    const [hasMoreMessages, setHasMoreMessages] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const access_token = localStorage.getItem('access_token');
    const time = localStorage.getItem('latest_created_at');
    const unreadMessageRefs = useRef({}); // Track message elements for unread messages
    const [errorCount, setErrorCount] = useState(0); // Track error count
    const [lastUsedFilters, setLastUsedFilters] = useState(null); // Зберігає останній використаний фільтр
    const [fetchAttempts, setFetchAttempts] = useState(0); // Лічильник спроб

    const makeLinksClickable = (text) => {
        const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
        return text.replace(urlPattern, (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`);
    };

    const filterMessages = useCallback((allMessages) => {
        return allMessages.filter((msg) => {
            const usernameMatch = !filters.username || filters.username.length === 0 || filters.username.includes(msg.username);
            const chatTitleMatch = !filters.chat_title || filters.chat_title.length === 0 || filters.chat_title.includes(msg.group);
            const contentMatch = !filters.content || filters.content.length === 0 || filters.content.some(content => msg.body.includes(content));
            const startsWithMatch = !filters.startswith || filters.startswith.length === 0 || filters.startswith.some(start => msg.body.startsWith(start));

            return usernameMatch && chatTitleMatch && contentMatch && startsWithMatch;
        });
    }, [filters]);

    const fetchMoreMessages = async () => {
        if (loadingMore || !hasMoreMessages || errorCount >= 3) return; // Stop if loading, no more messages, or error count exceeds 3

        const isSameFilter = JSON.stringify(filters) === JSON.stringify(lastUsedFilters);
        if (isSameFilter && fetchAttempts >= 3) {
            // console.log("Fetch limit reached for the same filters");
            return;
        }

        setLoadingMore(true);

        if (!isSameFilter) {
            setFetchAttempts(0); // Reset attempts for a new filter
            setLastUsedFilters(filters); // Update the last used filter
        } else {
            setFetchAttempts((prev) => prev + 1); // Increment fetch attempts
        }

        const firstMessageTime = messages[0]?.created_at || Date.now() / 1000;
        const limit = 20;
        // console.log("Fetching messages starting from:", firstMessageTime);

        const filtersPayload = {
            username: filters?.username?.length > 0 ? filters.username : null,
            chat_title: filters?.chat_title?.length > 0 ? filters.chat_title : null,
            content: filters?.content?.length > 0 ? filters.content : null,
            startswith: filters?.startswith?.length > 0 ? filters.startswith : null,
        };

        try {
            const response = await fetch(
                `https://noisefilter.app/api/messages/history/?unix_timestamp=${Math.floor(firstMessageTime)}&limit=${limit}`,
                {
                    method: 'POST',
                    headers: {
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `bearer ${access_token}`,
                    },
                    body: JSON.stringify(filtersPayload),
                }
            );

            if (response.ok) {
                const data = await response.json();
                // console.log("Fetched data:", data);

                if (Array.isArray(data) && data.length > 0) {
                    const newLatestCreatedAt = Math.max(...data.map((msg) => msg.created_at));
                    // console.log("Updated latest message time:", newLatestCreatedAt);
                    localStorage.setItem('latest_created_at', newLatestCreatedAt);

                    const previousScrollHeight = containerRef.current.scrollHeight;
                    const previousScrollTop = containerRef.current.scrollTop;

                    const formattedMessages = data.map((msg, index) => ({
                        id: msg.id ? `${msg.id}-${index}` : `temp-${Math.random()}`,
                        username: msg.sender_username || 'Unknown',
                        group: msg.chat_title || 'Unknown Group',
                        body: msg.text ? DOMPurify.sanitize(makeLinksClickable(msg.text)) : '',
                        type: msg.chat && msg.chat.chat_type ? msg.chat.chat_type : 'Group',
                        time: new Date(msg.created_at * 1000).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                        }),
                        created_at: msg.created_at,
                        date: new Date(msg.created_at * 1000).toLocaleDateString('en-US', {
                            month: 'long',
                            day: 'numeric',
                        }),
                        is_deleted: msg.is_deleted,
                        is_updated: msg.is_updated,
                    }));

                    const newMessages = [
                        ...formattedMessages,
                        ...messages.filter(
                            (msg) => !formattedMessages.some((formattedMsg) => formattedMsg.id === msg.id)
                        ),
                    ];

                    const filteredMessages = filterMessages(newMessages);
                    filteredMessages.sort((a, b) => a.created_at - b.created_at); // Sort by time

                    setMessages(filteredMessages);

                    setTimeout(() => {
                        if (containerRef.current) {
                            const currentScrollHeight = containerRef.current.scrollHeight;
                            containerRef.current.scrollTop = previousScrollTop + (currentScrollHeight - previousScrollHeight);
                        }
                    }, 0);
                } else {
                    setHasMoreMessages(false);
                }
            } else if (response.status === 401) {
                const newToken = await AuthService.refreshToken();
                if (newToken) {
                    localStorage.setItem('access_token', newToken);
                    await fetchMoreMessages(); // Retry after refreshing token
                }
            } else {
                setErrorCount((prev) => prev + 1);
            }
        } catch (error) {
            console.error("Fetch error:", error);
            setErrorCount((prev) => prev + 1);
        } finally {
            setLoadingMore(false);
        }
    };


    useImperativeHandle(ref, () => ({
        scrollToMessage: async (messageId) => {
            let messageElement = document.querySelector(`[id^="message-${messageId}"]`);

            // Якщо повідомлення не знайдено, чекаємо оновлення через WebSocket
            if (!messageElement) {
                // console.log("Message not found. Waiting for WebSocket updates...");

                const waitForMessage = new Promise((resolve, reject) => {
                    const timeoutId = setTimeout(() => {
                        clearInterval(intervalId);
                        // console.log("Message not found. Scrolling to last message...");

                        // Ось тут ти маєш використовувати реф для виклику scrollToLastMessage
                        if (ref.current && typeof ref.current.scrollToLastMessage === 'function') {
                            ref.current.scrollToLastMessage(); // Прокрутка до останнього повідомлення
                        }

                        reject("Message not found within timeout");
                    }, 1000); // Таймаут на очікування 1 секунда

                    const intervalId = setInterval(() => {
                        messageElement = document.querySelector(`[id^="message-${messageId}"]`);
                        if (messageElement) {
                            clearInterval(intervalId);
                            clearTimeout(timeoutId);
                            resolve(true);
                        }
                    }, 100);
                });

                try {
                    await waitForMessage;
                } catch (error) {
                    console.error(error);
                    return;
                }
            }

            // Якщо повідомлення знайдено, прокрутка до нього
            if (messageElement && containerRef.current) {
                // console.log(`Message found. Scrolling to message-${messageId}`);
                setTimeout(() => {
                    containerRef.current.scrollTop = messageElement.offsetTop;
                }, 100);
            }
        },
        scrollToLastMessage() {
            if (containerRef.current) {
                containerRef.current.scrollTop = containerRef.current.scrollHeight;
            }
        },
        fetchMoreMessages
    }));



    const renderRow = useCallback((index) => {
        const msg = messages[index];
        const prevMsg = messages[index + 1] || {};

        const currentDate = new Date(msg.created_at * 1000).toDateString();
        const prevDate = prevMsg.created_at ? new Date(prevMsg.created_at * 1000).toDateString() : null;

        // Determine the class for the message
        let messageClass = '';
        if (msg.is_deleted) {
            messageClass = 'deleted'; // Apply 'deleted' class if the message is deleted
        } else if (msg.is_updated) {
            messageClass = 'modified'; // Apply 'modified' class if the message is updated
        }


        return (
            msg && msg.username && (
                <div key={msg.id || index} id={`message-${msg.id || index}`} className="cart-message-wrapper" ref={(el) => unreadMessageRefs.current[msg.id] = el}>
                    <div className={`chat-message ${messageClass}`}>
                        <div className="chat-header">
                            <span className="chat-username">{msg.username}</span>
                            <span className="chat-group">In {msg.type} {msg.group}</span>
                        </div>
                        <div className="chat-body"
                             dangerouslySetInnerHTML={{__html: msg.body.replace(/<a /g, '<a target="_blank" rel="noopener noreferrer" ')}}></div>
                        <div className="chat-time">{msg.time}</div>
                    </div>
                </div>
            )
        );
    }, [messages]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const messageId = entry.target.id.replace('message-', '');
                        const numericMessageId = parseInt(messageId);

                        // Reduce the unread count if the message is unread and intersects
                        if (numericMessageId >= firstWebSocketMessageId) {
                            setUnreadCount(prevCount => Math.max(prevCount - 1, 0));
                        }

                        // Update the firstWebSocketMessageId if the message is scrolled past and it's a new one
                        if (numericMessageId >= firstWebSocketMessageId) {
                            setFirstWebSocketMessageId(numericMessageId);
                        }
                    }
                });
            },
            {
                root: containerRef.current,
                threshold: 1.0
            }
        );

        Object.values(unreadMessageRefs.current).forEach(el => {
            if (el) observer.observe(el);
        });

        return () => {
            Object.values(unreadMessageRefs.current).forEach(el => {
                if (el) observer.unobserve(el);
            });
        };
    }, [messages, firstWebSocketMessageId, setUnreadCount, setFirstWebSocketMessageId]);

    useEffect(() => {
        // Fetch messages when filters change
        // console.log("fetching with filters", filters)
        fetchMoreMessages(filters);
    }, [filters]); // Add filters as a dependency

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && hasMoreMessages && !loadingMore) {
                    fetchMoreMessages();
                }
            },
            {
                root: containerRef.current,
                threshold: 1.0
            }
        );

        if (loadMoreRef.current) {
            observer.observe(loadMoreRef.current);
        }

        return () => {
            if (loadMoreRef.current) {
                observer.unobserve(loadMoreRef.current);
            }
        };
    }, [hasMoreMessages, loadingMore]);

    // useEffect(() => {
    //     const filteredMessages = filterMessages(messages);
    //     setMessages(filteredMessages);
    //     // Removed `fetchMoreMessages` here to avoid double fetching
    // }, [filters, filterMessages]);


    return (
        <div
            id="scrollableDiv"
            className="chat-container-virtual-scroll"
            ref={containerRef}
        >
            <div ref={loadMoreRef} style={{ height: '1px' }}></div>
            {messages.map((msg, index) => renderRow(index))}
        </div>
    );
});