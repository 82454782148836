import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import InputField from '../input/InputField';
import "./register.css";
import HeaderImage from '../icon/img_30.png'; // Correct path to your image
import HeaderImagePhoto from '../icon/img_29.png'; // Correct path to your image
import HeaderImageWhite from '../icon/noiseWhite.png'; // Correct path to your image
import useFlexCenter from '../input/useFlexCenter';
import AuthService from "../webSocket/component/AuthService ";

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState("");
    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Loading state

    const navigate = useNavigate();

    useFlexCenter('flex-center');

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const checkAccount = async (accessToken) => {
        try {
            const response = await fetch(`https://noisefilter.app/api/accounts_all/user/me`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });

            if (response.ok) {
                const accountData = await response.json();
                // console.log(accountData);
                if (accountData.length === 0) {
                    setError("No account found with this user ID.");
                    setHasError(true);
                } else if (accountData[0].id) {
                    navigate("/home");
                } else {
                    navigate("/connect-telegram");
                }
            } else {
                setError("Failed to retrieve account details.");
                setHasError(true);
                navigate("/connect-telegram");  // Add this line to handle redirection

            }
        } catch (error) {
            console.error("Error during account check:", error);
            setError("An error occurred while checking the account. Please try again.");
            setHasError(true);
        }
    };

    const validate = async (e) => {
        e.preventDefault();

        // Check if email and password are filled in
        if (!email || !password) {
            setError("Please fill in both fields.");
            setHasError(true);
            return;
        }

        setIsLoading(true); // Start loading

        try {
            // console.log("Attempting to login with email:", email, "and password:", password);
            const response = await fetch('https://noisefilter.app/api/login/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: email,
                    password: password,
                }),
            });

            if (response.ok) {
                const data = await response.json();
                // console.log("Server response data:", data);

                if (data && data.access_token) {
                    // console.log("Login successful, saving tokens and checking account.");

                    localStorage.clear();

                    // Store tokens in localStorage
                    localStorage.setItem('access_token', data.access_token);
                    localStorage.setItem('refresh_token', data.refresh_token);
                    localStorage.setItem('userId', data.user.id); // Store user ID
                    localStorage.setItem('username', data.user.username); // Store user ID

                    // Check the account details with the received token
                    await checkAccount(data.access_token);
                } else {
                    setError("Invalid username or password.");
                    setHasError(true);
                    // console.log("Login failed: invalid credentials or missing tokens in response");
                }
            } else if (response.status === 401) {
                setError("Incorrect login or password");
                setHasError(true);
            } else {
                const errorData = await response.json();
                setError(errorData.detail ? errorData.detail[0].msg : "Login failed.");
                setHasError(true);
                // console.log("Error data:", errorData, "Email:", email, "Password:", password);
            }
        } catch (error) {
            console.error("Error during login:", error);
            setError("Unable to log in. Please try again.");

            setHasError(true);
        } finally {
            setIsLoading(false); // End loading
        }
    };


    return (
        <>
            {!isLoading && (
                <div className="header-container-white">
                    <img src={HeaderImagePhoto} alt="Header" className="header-image-white"/>
                    <img src={HeaderImage} alt="Header" className="header-image"/>
                </div>
            )}

            <center>
                {isLoading && (
                    <img src={HeaderImageWhite} alt="Loading..." className="loading-indicator"/>
                )}
            </center>

            <div className={`card-login ${isLoading ? 'loading' : ''}`}>
                <form onSubmit={validate}>
                    <center><h1>Login</h1></center>
                    <InputField
                        type="text"
                        placeholder="Username or Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <InputField
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        showPassword={showPassword}
                        togglePasswordVisibility={togglePasswordVisibility}
                        errorGmail={error}
                    />
                    <button
                        className={`submit-btn ${hasError ? 'error-margin' : ''}`}
                        type="submit"
                        disabled={isLoading} // Disable button when loading
                    >
                        {isLoading ? "Processing..." : "Continue"}
                    </button>
                    <p className="login-link" onClick={() => navigate("/")}>
                        Don’t have an account? Register
                    </p>
                </form>
            </div>
        </>
    );
}

export default Login;
